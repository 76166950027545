import { CustomLink } from 'data/types';
import React from 'react';
import cn from 'classnames';
import styles from './Footer.module.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import YouTube from '@mui/icons-material/YouTube';
import SvgIcon from '@mui/material/SvgIcon';
import { Link } from '@mui/material';
import logoLotus from 'images/LOGO_LOTUS_COLOR.png';
import stylesHeader from 'components/Header/Header.module.css';
import { useTranslation } from 'react-i18next';
import { SupportedLocales } from 'types/legacy/locale';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: (t: any) => WidgetFooterMenu[] = (t) => [
  {
    id: '1',
    title: t('footer.packages.about'),
    menus: [
      { href: '/packages/hotels/novotel', label: t('footer.hotels.novotel') },
      {
        href: '/packages/hotels/itu-plaza',
        label: t('footer.hotels.itu_plaza')
      }
    ]
  },
  {
    id: '2',
    title: 'FAQ',
    menus: [
      { href: '/faq/general', label: 'Geral' },
      { href: '/faq/shuttle', label: 'Shuttle' },
      {
        href: '/faq/accommodation',
        label: t('faq.accommodation.heading')
      },
      {
        href: '/faq/before-sales',
        label: t('faq.before_sales.heading')
      },
      {
        href: '/faq/during-saless',
        label: t('faq.during_sales.heading')
      },
      {
        href: '/faq/after-sales',
        label: t('faq.after_sales.heading')
      },
      {
        href: '/faq/payment-methods',
        label: t('footer.payment_options.methods')
      }
    ]
  },
  {
    id: '3',
    title: t('footer.payment_options.methods'),
    menus: [
      {
        href: '/faq/payment-methods',
        label: t('footer.payment_options.pagseguro')
      },
      {
        href: '/faq/payment-methods',
        label: t('footer.payment_options.paypal')
      }
    ]
  }
];

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();

  const languageMappting = {
    'pt-BR': 'pt',
    'en-GB': 'en',
    'es-ES': 'en'
  };

  const language = languageMappting[i18n.language] || 'en';

  const termsMapping: Record<SupportedLocales, string> = {
    'pt-BR': 'https://brasil.tomorrowland.com/pt/termos-condicoes',
    'en-GB': 'https://brasil.tomorrowland.com/en/terms-conditions',
    'es-ES': 'https://brasil.tomorrowland.com/en/terms-conditions'
  };

  const FOOTER_ITEMS = [
    // {
    //   href: `https://brasil.tomorrowland.com/${language}/privacy-policy/`,
    //   label: 'footer.privacy'
    // },
    {
      href: termsMapping[i18n.language as SupportedLocales],
      label: 'footer.terms'
    },
    {
      href: `https://brasil.tomorrowland.com/${language}/global-journey-terms-conditions`,
      label: 'footer.gj-terms'
    },
    {
      href: 'https://tomorrowlandbrasil.zendesk.com/hc/categories/24288784330388-Global-Journey-Travel-Packages',
      label: 'footer.faq'
    },
    {
      href: 'mailto:tomorrowlandbrasil@insidemusicland.com',
      label: 'footer.contact'
    }
  ];

  return (
    <div className="bottom-0 left-0 right-0 self-end">
      <div className="divider pb-8" />
      <div className=" flex flex-col relative items-center gap-4 pb-8">
        <img
          className="self-center pt-4"
          src="/images/tomorrowland/TML_LOGO_2016_VECTOR_FULL-WHITE.svg"
          width="200px"
        />
        <div className="flex flex-row gap-2 text-gray-400">
          <Link href="https://www.facebook.com/tomorrowlandbrasil" color="inherit" target="_blank">
            <SvgIcon>
              <FacebookIcon />
            </SvgIcon>
          </Link>
          <Link
            href="https://www.instagram.com/tomorrowlandbrasil/"
            color="inherit"
            target="_blank">
            <SvgIcon>
              <Instagram />
            </SvgIcon>
          </Link>
          <Link href="https://www.youtube.com/@tomorrowland" color="inherit" target="_blank">
            <SvgIcon>
              <YouTube />
            </SvgIcon>
          </Link>
        </div>
        <div className="text-center tracking-widest uppercase text-xs text-gray-500 flex flex-col sm:flex-row gap-2">
          {FOOTER_ITEMS.map((item) => (
            <div
              key={`footer_${item.label}`}
              className={cn(styles.footerLink, 'before:hidden sm:before:inline')}>
              <a
                target="_blank"
                className={styles.footerLinkText}
                href={item.href}
                rel="noreferrer">
                {t(item.label)}
              </a>
            </div>
          ))}
        </div>
        <div className="flex gap-1 pt-6">
          <div className={stylesHeader.gradientText}>by Inside Music Land</div>
          <div>
            <img alt="Inside Music Land logo" src={logoLotus} className="w-6 h-6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
