import React, { useEffect } from 'react';
import MyRouter from 'routers/index';
import { createTheme, THEME_ID as MATERIAL_THEME_ID, ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from '@mui/joy/styles';
import { ThemeProvider as MTThemeProvider } from '@material-tailwind/react';

const theme = extendTheme({
  components: {
    JoyInput: {
      styleOverrides: {
        root: {
          ':hover': {
            outline: 'none'
          },
          borderRadius: '2px'
        }
      }
    },
    JoyChip: {
      styleOverrides: {
        root: {
          borderRadius: '2px'
        }
      }
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: '2px'
        }
      }
    },
    JoySelect: {
      styleOverrides: {
        root: {
          borderRadius: '2px'
        }
      }
    },
    JoyButton: {
      styleOverrides: {
        root: {
          borderRadius: '2px'
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});

function App() {
  useEffect(() => {
    window.location.href = 'https://www.shop-globaljourneybrasil.insidemusicland.com/';
  }, []);
  
  return (
    <ThemeProvider theme={{ [MATERIAL_THEME_ID]: darkTheme as any }}>
      <JoyCssVarsProvider defaultMode="dark" theme={theme}>
        <MTThemeProvider>
          <div className="min-h-screen global-journey text-base text-neutral-900 dark:text-neutral-200">
            <MyRouter />
          </div>
        </MTThemeProvider>
        <Toaster />
        {/*{WIN_WIDTH < 768 && <FooterNav />}*/}
      </JoyCssVarsProvider>
    </ThemeProvider>
  );
}

export default App;
