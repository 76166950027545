import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.module.css';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import { Skeleton, useMediaQuery } from '@mui/material';

type ImagesCarouselProps = {
  imagesUrl: string[];
  isLoading?: boolean;
};
export default function ImagesCarousel(props: ImagesCarouselProps) {
  const matches = useMediaQuery('(min-width:600px)');
  if (props.isLoading) {
    return (
      <div>
        <Skeleton sx={{ transform: 'none' }} height={225} />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <Swiper
        slidesPerView={matches ? 3 : 1}
        spaceBetween={8}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper">
        {props.imagesUrl.map((url) => (
          <SwiperSlide key={url}>
            <img
              className="rounded-lg aspect-[3/2] sm:aspect-[4/4] object-cover"
              src={`${url}?tr=w-600,h-600`}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
