import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tomorrowlandLogo from 'images/tomorrowland-icons/TML_ICON_ORIGINAL_PANTONE_7425C.svg';
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const mapStyles = [
  {
    featureType: 'poi', // Points of interest
    stylers: [{ visibility: 'off' }] // Hide POIs
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }] // Hide transit lines and stations
  }
  // Add more style rules as needed
];

const mapOptions = {
  disableDefaultUI: true, // Disables all the controls
  zoomControl: false, // Specifically disables zoom controls
  streetViewControl: false, // Disable Street View
  mapTypeControl: false // Disable map type control
  // Add more options as needed
};

const festivalPosition = {
  lat: -23.3401494,
  lng: -47.3458979
};

const MapWithOverlay = ({ hotel }: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBCq1LLFBNrQunGDONKr-Jc3ULhWylvSJc'
  });
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(true);

  const handleOverlayClick = () => {
    setShowOverlay(false);
  };

  const accommodationPosition = useMemo(
    () => ({
      lat: hotel.location.coordinates.lat,
      lng: hotel.location.coordinates.lng
    }),
    [hotel.location.coordinates.lat, hotel.location.coordinates.lng]
  );

  const positions = useMemo(
    () => [accommodationPosition, festivalPosition],
    [accommodationPosition]
  );

  const mapRef = useRef(null);

  const onLoad = React.useCallback(
    function callback(map: any) {
      const bounds = new window.google.maps.LatLngBounds();
      positions.forEach((pos) => bounds.extend(pos));
      map.fitBounds(bounds);
      mapRef.current = map;
    },
    [positions]
  );

  const onUnmount = React.useCallback(function callback() {
    mapRef.current = null;
  }, []);

  return (
    <div className="relative overflow-hidden w-full h-[300px] sm:h-[400px] rounded-lg">
      {showOverlay && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-10 "
          onClick={handleOverlayClick}>
          <p className="text-xl font-bold text-white tracking-wide">
            {t('packageBuilder.clickToSeeMap')}
          </p>
        </div>
      )}
      {isLoaded ? (
        <GoogleMap
          ref={mapRef}
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={containerStyle}
          options={{
            styles: mapStyles, // Apply custom styles
            ...mapOptions // Apply map options to disable controls
          }}
          zoom={12}
          center={accommodationPosition}>
          <Marker key="1" position={accommodationPosition} />

          {/* Marker with Tomorrowland logo */}
          <Marker
            key="2"
            position={festivalPosition}
            icon={{
              url: tomorrowlandLogo,
              scaledSize: new window.google.maps.Size(50, 50) // Adjust size as needed
            }}
          />
          <Polyline
            path={[accommodationPosition, festivalPosition]}
            options={{ strokeColor: '#000000' }}
          />
        </GoogleMap>
      ) : null}
    </div>
  );
};

export default MapWithOverlay;
