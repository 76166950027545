import React, { useEffect } from 'react';
import { useQueryParams } from 'hooks/use-query';
import { useConsumerIdentifier } from 'hooks/use-consumer-identifier';
import { indentify } from '@common/analytics/use-analytics';
import { useFeatureFlag } from 'configcat-react';
import { useQuery } from 'react-query';
import { apiFetch } from 'utils/apiFetch';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { LoadingContainer } from 'pages/commom/loading-container';
import { ConstumerIdentifier } from 'types/user-identifier';
import { SupportedLocales } from 'types/legacy/locale';

const IDENTIFIER_PARAM = 'pld';

type IdentifyConsumerResponse = {
  consumer: ConstumerIdentifier;
  access: boolean;
  userHash: string;
};

const intercomLanguageMap: Record<SupportedLocales, string> = {
  'pt-BR': 'pt-BR',
  'en-GB': 'en',
  'es-ES': 'es'
};

export const ConsumerAuthContainer = ({ children }: { children: React.ReactNode }) => {
  const { t, i18n } = useTranslation();
  const language = intercomLanguageMap[i18n.language];
  const { value: forceConsumerIdentifierValue, loading: loadingFlag } = useFeatureFlag(
    'forceConsumerIdentifier',
    false
  );
  const query = useQueryParams();
  const identifier = query.get(IDENTIFIER_PARAM) || Cookies.get(IDENTIFIER_PARAM);
  const setConsumer = useConsumerIdentifier((s) => s.setConsumer);
  const consumer = useConsumerIdentifier((s) => s.consumer);

  const { data: consumerData, isLoading } = useQuery<IdentifyConsumerResponse>(
    ['consumer', identifier],
    () => apiFetch(`/identify-consumer/${identifier}`).get(),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(identifier),
      onSuccess: (data) => setConsumer(data.consumer)
    }
  );

  /**
   * Boot Intercom
   */
  useEffect(() => {
    // @ts-ignore
    window.Intercom('update', {
      email: consumerData?.consumer.data.email,
      user_hash: consumerData?.userHash,
      language_override: language
    });
  }, [language, consumerData]);

  /**
   * If the consumer is not set and the identifier is present, set the consumer
   */
  useEffect(() => {
    if (identifier) {
      Cookies.set(IDENTIFIER_PARAM, identifier, { expires: 1 / 144, path: '/' });

      // Explicitly use window.location.href to create a URL object
      const currentUrl = new URL(window.location.href); // Adjusted line

      // Use URLSearchParams to work with the query string
      const searchParams = new URLSearchParams(currentUrl.search);

      // Remove the 'pld' query parameter
      searchParams.delete('pld');

      // Set the search property of the current URL to the modified search parameters
      currentUrl.search = searchParams.toString();

      // Use pushState to update the URL without reloading the page
      window.history.pushState(null, '', currentUrl.href); // Note the use of currentUrl.href
    }
  }, [identifier]);

  /**
   * If the consumer is set, identify the consumer in the analytics
   */
  if (consumer) {
    consumer && void indentify(consumer);
  }

  if (isLoading) {
    return (
      <div className="h-screen">
        <LoadingContainer
          title={t('loadingStates.loading.title')}
          description={t('loadingStates.loading.description')}
        />
      </div>
    );
  }
  
  return <div>{children}</div>;
};
