// Extracted reusable functions
import { EventTicket, UseStepsFlow } from 'pages/packages/types';
import { JOURNEY_TYPE, STEPS } from 'pages/packages/constants';
import { OrderPackage } from 'types/order';
import { v4 as uuidv4 } from 'uuid';

export const getJourneys = (get: () => UseStepsFlow): OrderPackage['journeys'] => {
  const journeyType = get().flow?.journeyType;
  const location = get().steps?.[STEPS.JOURNEY_ORIGIN];
  const journeyReturnId = get().steps?.[STEPS.JOURNEY_RETURN];

  if (!journeyReturnId || !location) return undefined;

  return {
    id: journeyReturnId,
    type: journeyType || JOURNEY_TYPE.FLIGHT,
    location
  };
};

export const createRoomPackage = (
  roomId: string,
  accommodationId: string,
  checkinDate: string,
  checkoutDate: string,
  ticket?: EventTicket['id'],
  journeys?: OrderPackage['journeys']
) => {
  return {
    id: uuidv4(),
    accommodation: {
      id: accommodationId,
      room: { id: roomId, checkin: checkinDate, checkout: checkoutDate }
    },
    ticketId: ticket,
    journeys: journeys
  };
};
