import { AccommodationType } from 'types/accommodation';
import { faBed, faBusSimple, faPlane, faTicket } from '@fortawesome/pro-regular-svg-icons';

export const MAX_PAX_THRESHOLD = 6;

export const STEPS = {
  CHECKIN_DATE: 'checkin-date',
  CHECKOUT_DATE: 'checkout-date',
  ACCOMODATION_LIST: 'accomodation-list',
  ACCOMODATION: 'accomodation',
  JOURNEY_ORIGIN: 'journey-origin',
  JOURNEY_ORIGIN_COUNTRY: 'journey-origin-country',
  JOURNEY_ORIGIN_STATE: 'journey-origin-state',
  JOURNEY_TYPE: 'jouney-type',
  JOURNEY_OUTBOUND: 'journey-outbound',
  JOURNEY_RETURN: 'journey-return',
  TICKET_SELECTION: 'ticket',
  SUMMARY: 'summary'
} as const;

export const JOURNEY_DIRECTION = {
  OUTBOUND: 'outbound',
  RETURN: 'return'
} as const;

export const JOURNEY_TYPE = {
  FLIGHT: 'flight',
  BUS: 'bus'
} as const;

export const PRICE_TYPES = {
  TOTAL: 'total',
  PAX: 'per_person'
};

export const ICONS = {
  BED: faBed,
  PLANE: faPlane,
  TICKLET: faTicket,
  BUS: faBusSimple
} as const;

export const AccommodationTypes = {
  Hotel: 'hotel',
  Camping: 'camping'
} as Record<string, AccommodationType>;
