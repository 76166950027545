import React, { FC } from 'react';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import JourneyItinerary from 'pages/packages/shop/components/journey-itinerary';
import { useTranslation } from 'react-i18next';

import { JourneyDetail } from 'types/journeys';
import { findJourneyById } from 'pages/packages/shop/helpers/find-journey-by-id';
import { StepHeader } from 'pages/packages/shop/components/step-header';

type BusesScheduleProps = {
  routeType: 'outbound' | 'return';
  journeyType: 'bus' | 'flight';
  onSelect: (id: string, info: JourneyDetail) => void;
  journeyId?: string | null;
};

export const JourneySchedule: FC<BusesScheduleProps> = (props) => {
  const { t } = useTranslation();

  const list = useStepsFlow((steps) => steps.journeys);

  const journey = findJourneyById(list, props.journeyId);
  const route = journey?.[props.routeType];

  // TODO: handle with proper error UI
  if (!journey || !route) return null;

  return (
    <div>
      <StepHeader
        title={t(`packageBuilder.steps.journeySchedule.${props.journeyType}.title`, {
          context: props.routeType
        })}
        desc={t(`packageBuilder.steps.journeySchedule.${props.journeyType}.subtitle`, {
          context: props.routeType
        })}
      />
      <div className="flex flex-col gap-4 pt-6">
        <div
          key={journey.id}
          onClick={(e) => {
            e.preventDefault();
            props.onSelect(journey.id, route);
          }}>
          <JourneyItinerary journey={route} type={journey.type} />
        </div>
      </div>
    </div>
  );
};
