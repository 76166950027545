import React, { forwardRef, useMemo } from 'react';
import cn from 'classnames';
import Header from 'pages/packages/checkout/components/header';
import { useTranslation } from 'react-i18next';

import { OrderStatusType } from 'types/order';

type PackagesPageContainerType = {
  noSideBar?: boolean;
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  activeHeader?: 'shop' | 'summary' | 'checkout' | 'payment';
  orderStatus?: OrderStatusType;
  sidebarImage?: string;
  className?: string;
};

const DEFAULT_SIDEBAR_IMAGE = `https://ik.imagekit.io/insidemusicland/tomorrowland/elixir-stage.jpeg?tr=w-600,h-1000`;

export const PackagesPageContainer = forwardRef<HTMLDivElement, PackagesPageContainerType>(
  (props, ref) => {
    const { t } = useTranslation();

    const steps = useMemo(() => {
      switch (props.activeHeader) {
        case 'shop':
          return {
            shop: 'active',
            summary: 'pending',
            checkout: 'pending',
            payment: 'pending'
          };
        case 'summary':
          return {
            shop: 'done',
            summary: 'active',
            checkout: 'pending',
            payment: 'pending'
          };
        case 'checkout':
          return {
            shop: 'done',
            summary: 'done',
            checkout: 'active',
            payment: 'pending'
          };
        case 'payment':
          return {
            shop: 'done',
            summary: 'done',
            checkout: 'done',
            payment: props.orderStatus === 'confirmed' ? 'done' : 'active'
          };
      }

      return {
        shop: 'pending',
        summary: 'pending',
        checkout: 'pending',
        payment: 'pending'
      };
    }, [props.activeHeader, props.orderStatus]);

    return (
      <div className={cn(props.className || 'bg-black h-screen text-white flex flex-col')}>
        {!props.activeHeader ? null : (
          <Header
            steps={[
              {
                title: t('common.orderNav.shop'),
                status: steps.shop
              },
              // {
              //   title: t('common.orderNav.summary'),
              //   status: steps.summary
              // },
              { title: t('common.orderNav.checkout'), status: steps.checkout },
              { title: t('common.orderNav.order'), status: steps.payment }
            ]}
          />
        )}

        <div
          className="flex flex-grow overflow-auto  relative pt-12 scroll-mt-24 scroll-pt-24"
          ref={ref}>
          <div className="flex-grow overflow-auto pb-32 scroll-auto h-full">{props.children}</div>
          {props.noSideBar ? null : (
            <div className="bg-gray-900 w-1/3 flex-none hidden lg:block overflow-y-auto">
              {props.sidebar || (
                <img
                  className="w-full h-full object-cover opacity-60"
                  alt="Event background"
                  src={
                    props.sidebarImage
                      ? `${props.sidebarImage}?tr=w-600,h-1000,q-80`
                      : DEFAULT_SIDEBAR_IMAGE
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
);
