import * as React from 'react';
import { FC } from 'react';
import { HotelPerks } from 'types/legacy/hotel';
import LocalConvenienceStoreOutlinedIcon from '@mui/icons-material/LocalConvenienceStoreOutlined';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import BathroomOutlinedIcon from '@mui/icons-material/BathroomOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { Badge } from '@design-system/feedback/badge';

export const HotelPerkToIcon: FC<{ perk: HotelPerks }> = ({ perk }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'accomodationPerks'
  });

  const decorator = {
    reception_24h: <LocalConvenienceStoreOutlinedIcon />,
    air_conditioner: <AcUnitOutlinedIcon />,
    pool: <PoolOutlinedIcon />,
    hair_dryer: <AirOutlinedIcon />,
    luggage_storage: <LuggageOutlinedIcon />,
    private_bathroom: <BathroomOutlinedIcon />,
    market_place: <StorefrontIcon />
  };

  return (
    <Badge
      variant="outlined"
      color="pink"
      value={t(perk)}
      icon={<SvgIcon sx={{ fontSize: 16 }}>{decorator[perk]}</SvgIcon>}
    />
  );
};
