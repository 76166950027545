import React, { ErrorInfo, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SiteHeader from 'containers/SiteHeader';
import { PackageShop } from 'pages/packages/shop/package-shop';
import LandingPage from 'pages/landing-page/landing-page';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { ErrorBoundaryFallback } from 'pages/commom/components/error-boundary-fallback'; //
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from 'react-query';
import { track } from '@common/analytics/use-analytics';
import { ConsumerAuthContainer } from 'ConsumerAuthContainer';
import PackagesSection from 'pages/landing-page/packages-section';

const logError = (error: Error, info: ErrorInfo) => {
  void track('PAGE_ERROR', 'page', {
    error: error?.toString(),
    errorInfo: info?.componentStack?.toString()
  });
};

const MyRoutes = () => {
  const { fetchInventory } = useStepsFlow((s) => ({
    fetchInventory: s.fetchInventory
  }));

  const { isFetching } = useQuery(
    ['inventory', 'packages'],
    async () => {
      return fetchInventory();
    },
    {
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    useStepsFlow.setState({ isFetchingInventory: isFetching });
  }, [isFetching]);

  // // @ts-ignore
  // window.Intercom('boot', {
  //   api_base: 'https://api-iam.intercom.io',
  //   app_id: 'vlxohj1t',
  //   custom_launcher_selector: '#intercom-launcher'
  // });

  return (
    <BrowserRouter basename="/">
      <div className="flex flex-col h-screen ">
        <SiteHeader />
        <ErrorBoundary onError={logError} fallback={<ErrorBoundaryFallback />}>
          <ConsumerAuthContainer>
            <Routes>
              <Route
                path={`/`}
                element={
                  <LandingPage>
                    <PackagesSection />
                  </LandingPage>
                }
              />
              <Route path={`packages/shop/:type/:step?`} element={<PackageShop />} />
            </Routes>
          </ConsumerAuthContainer>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  );
};

export default MyRoutes;
