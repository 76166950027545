import { ChevronLeftOutlined } from '@mui/icons-material';
import React from 'react';

type EmptyStateContainerProps = {
  title: string;
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  isLoading?: boolean;
  illustration?: React.ReactNode;
};

export const EmptyStateContainer = (props: EmptyStateContainerProps) => {
  const illustration = props.illustration || null;

  return (
    <div className="h-screen flex flex-col justify-center items-center px-8">
      {props.isLoading ? (
        <div className="my-6 animate-spin rounded-full w-24 h-24 border-b-4 border-pink-800"></div>
      ) : (
        illustration
      )}
      <div className="mx-auto max-w-2xl items-center text-center justify-center flex flex-col gap-6">
        <h2 className="text-4xl font-bold tracking-tight text-gray-200 sm:text-4xl max-w-md ">
          {props.title}
        </h2>
        <p className="text-lg leading-8 text-gray-400">{props.description}</p>
        {props.buttonText && props.buttonAction ? (
          <button
            onClick={props.buttonAction}
            className="bg-none text-pink-600 hover:text-pink-800 font-bold py-2 px-4 rounded inline-flex items-center">
            <ChevronLeftOutlined />
            <span>{props.buttonText}</span>
          </button>
        ) : null}
      </div>
    </div>
  );
};
