import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { STEPS } from 'pages/packages/constants';
import priceToBRL from 'utils/priceToBRL';
import { PackageCard } from 'pages/packages/shop/components/package-card';
import { PackagePriceBottomNav } from 'pages/packages/shop/components/package-price-bottom-nav';
import { StepHeader } from 'pages/packages/shop/components/step-header';
import { StackCardOption, StackedCards } from '@design-system/components/stacked-cards';
import { AvailabilityChip } from 'pages/commom/components/availability-chip';

type CheckinDateProps = {
  onSelect: (date: string) => void;
};

export const TicketSelection: FC<CheckinDateProps> = (props) => {
  const {
    tickets,
    inventoryAvailability,
    updatePackageTicket,
    getTotalamount,
    accommodations,
    steps,
    accommodationsRooms,
    selectedPackages
  } = useStepsFlow((state) => ({
    tickets: state.tickets,
    updatePackageTicket: state.updatePackageTicket,
    getTotalamount: state.getTotalamount,
    steps: state.steps,
    inventoryAvailability: state.inventoryAvailability,
    selectedPackages: state.selectedPackages,
    accommodations: state.accommodations,
    accommodationsRooms: state.accommodationsRooms
  }));

  const { t } = useTranslation();

  const onContinue = useCallback(() => {
    // navigate(`${ROUTES.PackageSummary}`);
    props.onSelect?.('');
  }, [props]);

  const allTicketsSelect = selectedPackages.every((item) => item.ticketId);

  const totalPax = selectedPackages.reduce((acc, item) => {
    return (
      acc +
      (accommodationsRooms?.[item.accommodation.id]?.[item.accommodation.room.id]?.capacity || 0)
    );
  }, 0);

  const totalWithTickets = getTotalamount(selectedPackages);

  const roomsListTicketSelection = useMemo(() => {
    return selectedPackages.map((orderPackage, index) => {
      const accommodation = steps?.[STEPS.ACCOMODATION_LIST];

      const accommodationDetail = accommodations?.[accommodation || ''];
      const totalInfo = getTotalamount([{ ...orderPackage, ticketId: undefined }]);
      const room = accommodationsRooms?.[accommodation || ''][orderPackage.accommodation.room.id];
      const headerText = t(`common.packageNumber`, { count: index + 1 });

      const options: StackCardOption[] = Object.keys(tickets).map((ticketKey, index) => {
        const ticket = tickets[ticketKey];
        const roomCapacity = room?.capacity || NaN;
        const ticketPriceTotal = ticket.price * roomCapacity;
        const totalPrice = Math.ceil((ticketPriceTotal + totalInfo.total) / roomCapacity);
        // const price = t(`common.prices.${ticket.priceType}`, {
        //   price: priceToBRL.format(totalPrice)
        // });

        const notAvailable = inventoryAvailability.tickets[ticket.id]?.quantity < totalPax;

        return {
          id: ticket.id,
          disabled: notAvailable,
          name: ticket.name,
          description: t(`tickets:${ticketKey}.description`),
          price: notAvailable ? (
            <AvailabilityChip
              {...inventoryAvailability.tickets[ticket.id]}
              isOnHold={false}
              isSoldOut={inventoryAvailability.tickets[ticket.id].isSoldOut || notAvailable}
            />
          ) : (
            priceToBRL.format(totalPrice)
          ),
          priceLabel: notAvailable ? '' : t('common.perPerson')
        };
      });

      return (
        <div key={`${orderPackage.id}_${index}`}>
          <PackageCard
            header={headerText}
            helpText={`${t(`accommodation:${accommodationDetail?.id}.name`)} - ${t(
              `accommodation_room:${room?.id}.name`
            )}`}>
            <StackedCards
              value={options.find((opt) => opt.id === orderPackage.ticketId)}
              onChange={(opt) => updatePackageTicket(orderPackage.id, opt.id)}
              options={options}
            />
          </PackageCard>
        </div>
      );
    });
  }, [
    accommodations,
    accommodationsRooms,
    getTotalamount,
    inventoryAvailability.tickets,
    selectedPackages,
    steps,
    t,
    tickets,
    totalPax,
    updatePackageTicket
  ]);

  useEffect(() => {
    /**
     * If the ticket is not available, select the first available ticket
     */
    selectedPackages.forEach((orderPackage) => {
      if (
        orderPackage.ticketId &&
        inventoryAvailability.tickets[orderPackage.ticketId]?.quantity < totalPax
      ) {
        const ticket = Object.entries(tickets)
          .map(([key, value]) => value)
          .find((ticket) => {
            return inventoryAvailability.tickets[ticket.id]?.quantity >= totalPax;
          });
        updatePackageTicket(orderPackage.id, ticket?.id);
      }
    });
  }, [inventoryAvailability.tickets, selectedPackages, tickets, totalPax, updatePackageTicket]);

  return (
    <div className="mb-12">
      <div className="relative">
        <div className="pb-12 md:pb-0">
          <div className="">
            <StepHeader step="ticketSelection" />
            <div className="flex flex-col gap-10 pt-6">{roomsListTicketSelection}</div>
          </div>
        </div>
      </div>

      <PackagePriceBottomNav
        disabled={!allTicketsSelect}
        total={totalWithTickets.total}
        pax={totalWithTickets.pax}
        onContinue={onContinue}
      />
    </div>
  );
};
