import { ListDivider, ListItemDecorator } from '@mui/joy';
import List from '@mui/joy/List';
import React, { FC } from 'react';
import cn from 'classnames'; // Create a light theme instance
import ListItem from '@mui/joy/ListItem'; // Create a light theme instance
import { PackageCard, PackagePrice } from 'pages/packages/shop/components/package-card';
import { useStepsFlow } from 'pages/packages/shop/hooks/use-steps-flow';
import { useTranslation } from 'react-i18next';
import { OrderPackage } from 'types/order';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type SummaryCardProps = {
  compact?: boolean;
  orderPackage: OrderPackage;
  count?: number;
  total?: number;
  pax: number;
  withPaymentMethods?: boolean;
  onRemove?: (id: string) => void;
};

const SummaryItem = (props: { name: string; desc?: string; icon?: IconProp; asset?: boolean }) => {
  return (
    <>
      <ListItem>
        {props.icon ? (
          <ListItemDecorator>
            <FontAwesomeIcon icon={props.icon} className="text-gray-800 text-sm" />
          </ListItemDecorator>
        ) : null}
        <div className="flex flex-col gap-1 py-1">
          <div
            className={cn(
              'text-xs font-semibold',
              props.asset ? 'text-gray-700' : 'text-gray-800 '
            )}>
            {props.name}
          </div>
          {props.desc ? <div className="text-gray-600 text-xs">{props.desc}</div> : null}
        </div>
      </ListItem>
      <ListDivider className="last:hidden" />
    </>
  );
};

export const SummaryCard: FC<SummaryCardProps> = ({
  count,
  total,
  pax,
  orderPackage,
  withPaymentMethods = false,
  compact = false
}) => {
  const { accommodationsRooms, accommodations } = useStepsFlow((state) => ({
    steps: state.steps,
    accommodations: state.accommodations,
    accommodationsRooms: state.accommodationsRooms
  }));
  const { t } = useTranslation();
  const getPackageItems = useStepsFlow((state) => state.getPackageItems);
  const priceFooter = compact ? null : (
    <PackagePrice withPaymentMethods={withPaymentMethods} pax={pax} total={total || 0} />
  );
  const room =
    accommodationsRooms?.[orderPackage.accommodation.id][orderPackage.accommodation.room.id];
  const accommodation = accommodations?.[orderPackage?.accommodation.id || ''];

  return (
    <div key={orderPackage.id}>
      <PackageCard
        header={t('orderSummary.packageNumber', { n: count })}
        footer={priceFooter}
        helpText={`${t(`accommodation:${accommodation?.id}.name`)} - ${t(
          `accommodation_room:${room?.id}.name`
        )}`}>
        <div className={`grid grid-cols-1 gap-4 md:gap-8 md:grid-cols-2`}>
          <div className="">
            <div>
              <div className="pb-1 pl-2 text-black text-xs tracking-wide uppercase font-bold">
                {t('orderSummary.packageItems')}
              </div>
              <List
                size="sm"
                variant="plain"
                sx={{
                  borderRadius: 'sm',
                  pb: 0
                }}>
                {getPackageItems(orderPackage, t).items.map((item, index) => {
                  return (
                    <SummaryItem
                      key={item.name}
                      name={item.name}
                      desc={item.desc}
                      icon={item.icon}
                    />
                  );
                })}
              </List>
            </div>
          </div>
          <div className="">
            <div className="pb-2 pl-2 text-black text-xs tracking-wide uppercase font-bold">
              {t('orderSummary.alsoIncluded')}
            </div>
            <List
              size="sm"
              variant="plain"
              sx={{
                borderRadius: 'sm'
              }}>
              {getPackageItems(orderPackage, t).assets.map((item) => {
                return <SummaryItem asset key={item.name} name={item.name} icon={item.icon} />;
              })}
            </List>
          </div>
        </div>
      </PackageCard>
    </div>
  );
};
