import { Journey, JourneyLocation } from 'types/journeys';

export function findJourneyById(
  journeyLocations: JourneyLocation,
  journeyId?: string | null
): Journey | undefined {
  for (const location of Object.values(journeyLocations)) {
    if (journeyId && location[journeyId]) {
      return location[journeyId];
    }
  }
  return undefined;
}
