import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { Enumerable } from 'types/legacy/common';
import { SupportedLocales } from 'types/legacy/locale';
import { useEnv } from 'hooks/use.env';
import { track } from '@common/analytics/use-analytics';

export const ACCEPTED_LANGUAGES: Record<string, SupportedLocales> = {
  PT: 'pt-BR',
  ES: 'es-ES',
  EN: 'en-GB'
} as const;

export type AcceptedLanguages = Enumerable<typeof ACCEPTED_LANGUAGES>;

export const headerLanguage: {
  id: AcceptedLanguages;
  name: string;
  description: string;
  active?: boolean;
}[] = [
  {
    id: ACCEPTED_LANGUAGES.PT,
    name: 'Português',
    description: 'languages.pt',
    active: true
  },
  {
    id: ACCEPTED_LANGUAGES.EN,
    name: 'English',
    description: 'languages.en'
  },
  {
    id: ACCEPTED_LANGUAGES.ES,
    name: 'Español',
    description: 'languages.es'
  }
];

interface LangDropdownProps {
  panelClassName?: string;
}

export const INSIDE_LNG = 'inside_lng';

const LangDropdown: FC<LangDropdownProps> = ({
  panelClassName = 'z-10 w-screen max-w-[80px] mt-0 right-0 sm:px-0'
}) => {
  const { i18n, t } = useTranslation();
  const { isLocalDev } = useEnv();

  const handleLangChange = async (lng: string, closeFn: any) => {
    Cookies.set(INSIDE_LNG, lng);
    await i18n.changeLanguage(lng);
    closeFn();
  };

  const languages = useMemo(() => {
    if (isLocalDev) {
      return [
        ...headerLanguage,
        {
          id: 'pseudo',
          name: 'pseudo',
          description: 'pseudo'
        }
      ];
    }
    return headerLanguage;
  }, [isLocalDev]);

  return (
    <div className="LangDropdown w-17 md:w-24 z-max">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-80'}
             group px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-300 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none`}>
              <img
                alt="country flag"
                className="w-4"
                src={`https://flagsapi.com/${i18n.language.split('-')[1]}/flat/32.png`}
              />

              <span className="ml-2 select-none font-bold text-sm">
                {i18n.language.split('-')[0].toUpperCase()}
              </span>
              <ChevronDownIcon
                className={`${open ? '-rotate-180' : 'text-opacity-70'}
                  ml-2 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className={`absolute ${panelClassName}`}>
                <div className="overflow-hidden shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-4 bg-white dark:bg-white p-4 mt-1 lg:grid-cols-1 justify-items-center ">
                    {languages.map((item, index) => (
                      <a
                        key={index}
                        href={'#'}
                        onClick={async () => {
                          await handleLangChange(item.id, close);
                          void track('language_switch_select', 'track', {
                            lang: item.id
                          });
                        }}
                        className={`flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 `}>
                        <div className="flex gap-2 items-center">
                          <img
                            alt="country flag"
                            className="w-4"
                            src={`https://flagsapi.com/${item.id.split('-')[1]}/flat/32.png`}
                          />
                          <p className="text-sm font-medium text-gray-600 dark:hover:text-black ">
                            {t(item.id.split('-')[0].toUpperCase())}
                          </p>

                          {/*<p className="text-xs text-gray-500 dark:text-gray-600">*/}
                          {/*  {item.name}*/}
                          {/*</p>*/}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
