import { API } from 'aws-amplify';

// CheckoutCreateRequest // checkout/create-request => return {hash}
// CheckoutSubmitPayment // checkout/submit-payment/{hash}
// OrderStatus // order/status/{hash}
// OrderPersonalisation // order/personalisation/{hash}

export const API_URLS = {
  INVENTORY: '/inventory/packages/shop',
  INVENTORY_AVAILABILITY: '/inventory/availability',
  CHECKOUT_REQUEST: '/checkout/request',
  CHECKOUT_SUBMIT_PAYMENT: '/checkout/payment',
  ORDER_STATUS: '/order/status',
  PAYPAL_CREATE_ORDER: '/checkout/paypal/create-order', // checkout/paypal/{action}/{checkoutId}
  PAYPAL_EXECUTE_PAYMENT: '/checkout/paypal/execute' // checkout/paypal/{action}/{checkoutId}
};

const filterEmptyParams = (queryParams?: Record<string, string>) => {
  const params = new URLSearchParams();
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        params.append(key, value);
      }
    });
  }
  return params;
};

export const apiFetch = <T = any>(path: string) => {
  return {
    get: async (queryParams?: Record<string, string>): Promise<T> => {
      const params = filterEmptyParams(queryParams);
      
      return (await API.get('InsideStorefrontApi', `${path}?${params}`, {})) as Promise<T>;
    },
    post: async <Z = any>(body: Z) =>
      (await API.post('InsideStorefrontApi', path, {
        body
      })) as Promise<T>
  };
};

export const QUERY_PARAMS = {
  HOTEL_ID: 'x-hotel',
  PACKAGE_ID: 'x-package',
  PACKAGE_GROUP_ID: 'x-package-gp',
  BUSIDS: 'busIds'
};
