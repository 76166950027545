import { PackageSummary } from 'pages/packages/types';
import { ICONS, JOURNEY_TYPE } from 'pages/packages/constants';
import { formatDateTime } from 'pages/packages/shop/hooks/helpers/format-date-time';
import { Journey } from 'types/journeys';
import { OrderPackage } from 'types/order';

export const buildJourneyData = (
  journeys: NonNullable<OrderPackage['journeys']>,
  journeyDetails: Journey,
  quantity: number
) => {
  const items: PackageSummary[] = [];
  const icon = journeys?.type === JOURNEY_TYPE.FLIGHT ? ICONS.PLANE : ICONS.BUS;
  const outboundFlight = journeyDetails.outbound;

  const item = {
    name: `${quantity}x ${outboundFlight.departure.name}  → ${outboundFlight.arrival.name}`,
    desc: `${formatDateTime(outboundFlight.departure.date)} - ${formatDateTime(
      outboundFlight.arrival.date
    )}`,
    icon
  };
  items.push(item);

  const returnFlight = journeyDetails.return;
  if (returnFlight) {
    const item = {
      name: `${quantity}x ${returnFlight.departure.name}  → ${returnFlight.arrival.name}`,
      desc: `${formatDateTime(returnFlight.departure.date)} - ${formatDateTime(
        returnFlight.arrival.date
      )}`,
      icon
    };
    items.push(item);
  }

  return items;
};
