import { RoomType } from 'pages/packages/types';
import { AccommodationRoomType } from 'types/accommodation';

export function findLowestPriceRoom(rooms: RoomType, checkinDate: string, checkoutDate: string) {
  let lowestPriceRoom: any = null;

  Object.entries(rooms).forEach(([roomId, room]) => {
    const priceDetails = room.prices?.find((price) => {
      return price.checkin === checkinDate && price.checkout === checkoutDate;
    });

    if (priceDetails) {
      const totalPrice = priceDetails.price / room.capacity;
      if (!lowestPriceRoom || totalPrice < lowestPriceRoom.price) {
        lowestPriceRoom = { roomId, price: totalPrice };
      }
    }
  });

  return lowestPriceRoom.price;
}

export const hasCheckinDatesAvailable = (
  rooms: RoomType,
  checkinDate: string,
  checkoutDate: string
) => {
  let isAvailable = true;

  for (const [, room] of Object.entries(rooms)) {
    isAvailable = room.prices?.some((price) => {
      return price.checkin === checkinDate && price.checkout === checkoutDate;
    });
    if (isAvailable) return true;
  }

  return isAvailable;
};

export const roomHasAvailability = (
  room: AccommodationRoomType,
  checkinDate?: string,
  checkoutDate?: string
) => {
  return room.prices?.some((price) => {
    return price.checkin === checkinDate && price.checkout === checkoutDate;
  });
};
