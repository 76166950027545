import React, { FC } from 'react';
import { LinearProgress } from '@mui/joy';
import SvgIcon from '@mui/material/SvgIcon';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Active = ({ title, index }: { title: string; index: number }) => (
  <li className="flex items-center space-x-3 text-left sm:space-x-2">
    <a
      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-600 text-xs font-semibold text-white"
      href="#">
      {index}
    </a>
    <span className="font-semibold text-gray-900">{title}</span>
  </li>
);

const Done = ({ title, index }: { title: string; index: number }) => (
  <li className="flex items-center space-x-3 text-left sm:space-x-2">
    <a
      className="flex h-6 w-6 items-center justify-center rounded-full bg-green-200 text-xs font-semibold text-black"
      href="#">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="2">
        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
      </svg>
    </a>
    <span className="font-semibold text-gray-900">{title}</span>
  </li>
);

const Pending = ({ title, index }: { title: string; index: number }) => (
  <li className="flex items-center space-x-3 text-left sm:space-x-4">
    <a
      className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-400 text-xs font-semibold text-white"
      href="#">
      {index}
    </a>
    <span className="font-semibold text-gray-500">{title}</span>
  </li>
);

const Separator = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-4 w-4 text-black"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth="2">
    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
  </svg>
);

type HeaderProps = { progressValue?: number; steps: { title: string; status: string }[] };

const Header: FC<HeaderProps> = ({ steps, progressValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="z-20 top-[40px] w-full fixed items-center">
      <div className="flex flex-col items-center border-b bg-white py-1 sm:flex-row p-12 shadow-sm">
        <div
          role="link"
          onClick={() => {
            navigate('/');
          }}
          className="hidden md:block cursor-pointer text-black hover:text-pink-700 items-center">
          <SvgIcon fontSize="small">
            <ChevronLeftOutlined />
          </SvgIcon>
          <span className="decoration-0 text-sm font-semibold">{t('common.orderNav.goBack')}</span>
        </div>

        <div className="py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div className="relative">
            <ul className="relative flex w-full items-center justify-between space-x-1 sm:space-x-2">
              {steps.map((step, index) => {
                return (
                  <React.Fragment key={index}>
                    {step.status === 'done' && <Done title={step.title} index={index + 1} />}
                    {step.status === 'active' && <Active title={step.title} index={index + 1} />}
                    {step.status !== 'done' && step.status !== 'active' && (
                      <Pending title={step.title} index={index + 1} />
                    )}
                    {index < steps.length - 1 && <Separator />}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {progressValue ? (
        <LinearProgress color="primary" thickness={3} value={progressValue} determinate />
      ) : null}
    </div>
  );
};

export default Header;
