import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { forwardRef, HTMLProps } from 'react';

type LandingPageCardProps = {
  title: string;
  key: string;
  description: string;
  icon: React.ReactNode;
  ctaText: string;
  onClick: () => void;
} & HTMLProps<HTMLDivElement>;

export const LandingPageCard = forwardRef<HTMLDivElement, LandingPageCardProps>(
  ({ onClick, ctaText, title, icon, description, ...props }, ref) => {
    return (
      <div
        {...props}
        role="button"
        onClick={onClick}
        className="peer hover:outline outline-gray-800 h-fit md:h-[200px] relative isolate overflow-hidden bg-gray-900/60 shadow-2xl rounded-md px-10 py-8 items-center md:items-start flex-col md:flex-row flex gap-1 justify-between">
        <div className="max-w-md text-center lg:text-left">
          <h2 className="text-2xl font-bold tracking-tight text-white flex flex-col md:flex-row justify-between items-center gap-2">
            <span className="border-b-2 border-pink-700">{title}</span> {icon || null}
          </h2>
          <p className="mt-4 text-sm leading-8 text-gray-300">{description}</p>
        </div>
        <div className="flex gap-1 mt-4 self-center text-pink-600 md:text-white">
          <span className="md:hidden">{ctaText}</span>
          <ArrowForwardIosIcon />
        </div>
      </div>
    );
  }
);
