import CryptoJS from 'crypto-js';

import { OrderPackage } from 'types/order';

export const OrderCrypto = (session: string) => {
  return {
    encode: (data: OrderPackage[]) => {
      const hash = CryptoJS.AES.encrypt(JSON.stringify(data), session);
      return encodeURIComponent(hash.toString());
    },
    decode: (hash: string): OrderPackage[] => {
      const bytes = CryptoJS.AES.decrypt(decodeURIComponent(hash), session);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) as OrderPackage[];
    }
  };
};
