import { create } from 'zustand';
import { produce } from 'immer';
import { PackageSummary, UseStepsFlow } from 'pages/packages/types';
import { JOURNEY_TYPE, STEPS } from 'pages/packages/constants';
import { getJourneyPrice } from 'pages/packages/shop/helpers/get-journey-price';
import { ROUTES } from 'contants';
import {
  createRoomPackage,
  getJourneys
} from 'pages/packages/shop/hooks/helpers/selected-packages';
import { TFunction } from 'i18next';
import { buildAccommodationData } from 'pages/packages/shop/hooks/helpers/build-accommodation-data';
import { buildJourneyData } from 'pages/packages/shop/hooks/helpers/build-journey-data';
import { buildTicketData } from 'pages/packages/shop/hooks/helpers/build-ticket-data';
import { API_URLS, apiFetch } from 'utils/apiFetch';
import { OrderPackage } from 'types/order';
import { findJourneyById } from 'pages/packages/shop/helpers/find-journey-by-id';
import { InventoryAvailability } from 'types/inventory-availability';

export const useStepsFlow = create<UseStepsFlow>(
  /** Represents the steps flow state and provides various methods to manage and manipulate the state. */ (
    set,
    get
  ) => ({
    orderPackages: [],
    journeys: {},
    assets: {},
    selectedPackages: [],
    currentStepIndex: 0,
    accommodationsRooms: {},
    tickets: {},
    accommodations: {},
    inventoryAvailability: {
      accommodationRooms: {},
      tickets: {},
      journeys: {}
    },
    fetchInventoryAvailability: async () => {
      set(
        produce<UseStepsFlow>((state) => {
          state.isFetchingInventory = true;
        })
      );

      const response = await apiFetch<InventoryAvailability>(API_URLS.INVENTORY_AVAILABILITY).get();

      set(
        produce<UseStepsFlow>((state) => {
          state.isFetchingInventory = false;
          state.inventoryAvailability = response;
        })
      );
    },
    fetchInventory: async () => {
      set(
        produce<UseStepsFlow>((state) => {
          state.isFetchingInventory = true;
        })
      );

      const response = await apiFetch<{
        accommodations: UseStepsFlow['accommodations'];
        tickets: UseStepsFlow['tickets'];
        accommodationRooms: UseStepsFlow['accommodationsRooms'];
        assets: UseStepsFlow['assets'];
        journeys: UseStepsFlow['journeys'];
      }>(API_URLS.INVENTORY).get();

      set(
        produce<UseStepsFlow>((state) => {
          state.isFetchingInventory = false;
          state.accommodationsRooms = response.accommodationRooms;
          state.accommodations = response.accommodations;
          state.tickets = response.tickets;
          state.assets = response.assets;
          state.journeys = response.journeys;
        })
      );
    },
    setStepsOrder: (order) => {
      set(
        produce((state) => {
          state.stepsOrder = order;
          state.currentStep = order[0];
        })
      );
    },
    setSteps: (steps) => {
      set(
        produce((state) => {
          state.steps = steps;
        })
      );
    },
    setStep: (step, value) => {
      set(
        produce((state) => {
          state.steps = {
            ...state.steps,
            [step]: value
          };
        })
      );
    },
    setFlow: (flow) => {
      set(
        produce((state) => {
          state.flow = flow;
        })
      );
    },
    getTotalamount: (selectedPackages?: OrderPackage[]) => {
      const DEFAULT_TOTALS = { total: 0, pax: 0 };
      const packagesList = selectedPackages || get().selectedPackages;
      const flightPrice = get().selectedJourneyPrice();

      return packagesList.reduce((acc, orderPackage) => {
        const ticketId = orderPackage.ticketId;
        const ticketPrice = ticketId ? get().tickets?.[ticketId]?.price : 0;
        // accommodation details
        const accommodation =
          get().accommodationsRooms?.[orderPackage.accommodation.id][
            orderPackage.accommodation.room.id
          ];

        // price details
        const price = accommodation?.prices.find(
          (price) =>
            price.checkin === orderPackage.accommodation.room.checkin &&
            price.checkout === orderPackage.accommodation.room.checkout
        );

        return produce(acc, (draft) => {
          const roomPrice = price?.price || 0;
          const roomCapacity = accommodation?.capacity || 0;

          draft.total += roomPrice + (ticketPrice + flightPrice) * roomCapacity;
          draft.pax += roomCapacity;
        });
      }, DEFAULT_TOTALS);
    },
    resetSelectedRooms: () => {
      set(
        produce<UseStepsFlow>((state) => {
          state.selectedPackages = [];
        })
      );
    },
    setSelectedPackages: (roomId, quantity) => {
      const checkinDate = get().steps?.[STEPS.CHECKIN_DATE];
      const checkoutDate = get().steps?.[STEPS.CHECKOUT_DATE];
      const accommodationId = get().steps?.[STEPS.ACCOMODATION_LIST];

      // validate data
      if (!roomId || !checkinDate || !checkoutDate || !accommodationId) {
        throw new Error('Invalid data to create room package');
      }

      const tickets = Object.values(get().tickets).find((ticket) => ticket.price === 0);
      const journeys = getJourneys(get);
      // const room = get().accommodationsRooms?.[accommodationId][roomId];

      // Create room packages
      const newRoomPackages = Array.from({ length: quantity }, (x) =>
        createRoomPackage(roomId, accommodationId, checkinDate, checkoutDate, tickets?.id, journeys)
      );

      set(
        produce<UseStepsFlow>((state) => {
          const existingRooms = state.selectedPackages.filter(
            (p) => p.accommodation.room.id !== roomId
          );
          state.selectedPackages = [...existingRooms, ...newRoomPackages];
        })
      );
    },
    selectedJourneyPrice: () => {
      const journeyType = get().flow?.journeyType;

      return journeyType
        ? // get price for a single type
          getJourneyPrice(get, journeyType)
        : // get all
          Object.values(JOURNEY_TYPE).reduce((acc, journeyKey) => {
            return acc + getJourneyPrice(get, journeyKey);
          }, 0);
    },
    onSelectStep: (step, type, value) => {
      get().setStep(step, value);

      const currentStepIndex = get().stepsOrder?.indexOf(step) || 0;
      const href = `${ROUTES.PackageBuider}/${type}/${get().stepsOrder?.[currentStepIndex + 1]}`;
      // const params = getStepsUrlParams(get().steps);

      return {
        pathname: href,
        search: ''
      };
    },
    updatePackageTicket: (packageId, ticketId) => {
      set(
        produce<UseStepsFlow>((state) => {
          const packageToUpdate = state.selectedPackages.find((p) => p.id === packageId);

          if (packageToUpdate) {
            packageToUpdate.ticketId = ticketId;
          }
        })
      );
    },
    getPackageItems: (orderPackage: OrderPackage, t: TFunction) => {
      const items: { items: PackageSummary[]; assets: PackageSummary[] } = {
        items: [],
        assets: []
      };
      const { accommodations, accommodationsRooms, assets, journeys, tickets } = get();
      const accommodation = accommodations?.[orderPackage.accommodation.id];
      const room =
        accommodationsRooms?.[orderPackage.accommodation.id][orderPackage.accommodation.room.id];

      return produce(items, (draft) => {
        if (accommodation && room) {
          const accomodationData = buildAccommodationData(
            get,
            orderPackage,
            t,
            accommodation,
            room
          );
          draft.items.push(...accomodationData.items);
          draft.assets.push(...accomodationData.assets);
        }

        if (orderPackage.journeys) {
          const journeyDetails = findJourneyById(journeys, orderPackage.journeys.id);

          if (!journeyDetails) return;

          const journeyItems = buildJourneyData(
            orderPackage.journeys,
            journeyDetails,
            room?.capacity || 0
          );
          draft.items.push(...journeyItems);
        }

        if (orderPackage.ticketId) {
          draft.items.push(buildTicketData(get, orderPackage.ticketId, room?.capacity || 0, t));
        }
      });
    }
  })
);
