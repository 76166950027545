import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from 'shared/Logo/Logo';
import LangDropdown from 'components/Header/LangDropdown';

const SiteHeader = () => {
  return (
    <>
      <Helmet>
        <title>Global Journey - Tomorrowland Brasil</title>
      </Helmet>
      <div
        className={`bg-black fixed h-[40px] top-0 w-full left-0 right-0 z-40 shadow-gray-900 shadow-sm `}>
        <div className={`relative z-10 text-gray-300 `}>
          <div className="flex">
            <div className="flex flex-1 items-center space-x-3 my-[2px] ">
              <Link to="/" className={``}>
                <div className="flex gap-1 text-[10px] font-bold ml-2 pr-2">
                  <div className="px-2 border-r  border-gray-800">
                    <Logo maxH="max-h-4" />
                  </div>
                  {/*<div className={cn(styles.gradientText, ' md:block tracking-wider')}>*/}
                  {/*  GLOBAL JOURNEY*/}
                  {/*</div>*/}
                </div>
              </Link>
            </div>
            <div className="justify-end inline-flex flex-shrink-0 items-center flex-1 lg:flex-none text-gray-300 ">
              <div className="border-x border-gray-800">
                <LangDropdown />
              </div>
            </div>
            <div className="items-center lg:flex lg:space-x-1 lg:mx-6 mx-3 font-bold text-sm self-center">
              <a
                href="https://tomorrowlandbrasil.zendesk.com/hc/categories/24288784330388-Global-Journey-Travel-Packages"
                target="_blank"
                className="self-center"
                rel="noreferrer">
                FAQ
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteHeader;
