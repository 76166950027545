import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { ConstumerIdentifier } from 'types/user-identifier';
import { createJSONStorage, persist } from 'zustand/middleware';

export type useCheckoutStoreState = {
  setConsumer: (consumer: ConstumerIdentifier) => void;
};

export type useCheckoutStoreActions = {
  consumer?: ConstumerIdentifier;
};

export const useConsumerIdentifier = create(
  persist(
    immer<useCheckoutStoreActions & useCheckoutStoreState>((set) => ({
      consumer: undefined,
      setConsumer: (consumer) => {
        try {
          if (consumer) {
            set((state) => {
              state.consumer = consumer;
            });
          }
        } catch (error) {
          console.error('Invalid consumer');
        }
      }
    })),
    {
      name: 'inside-external-consumer',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
