import React from 'react';
import { Icon } from '@mui/material';
import { GlobalJourneyIcon } from 'images/tomorrowland-brasil/gj-logo';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  maxH?: string;
}

const Logo: React.FC<LogoProps> = ({ maxH = 'max-h-12' }) => {
  return <GlobalJourneyIcon className={`block w-9 h-9 fill-pink-700 `} />;
};

export const LogoLeaf = () => {
  return (
    <Icon className="fill-amber-100">
      <img
        width="26px"
        src="https://ik.imagekit.io/insidemusicland/inside/LOGO_LEAF.svg"
        alt="Inside Music Land Logo"
      />
    </Icon>
  );
};

export default Logo;
