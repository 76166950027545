import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';
import { INSIDE_LNG } from 'components/Header/LangDropdown';
import Pseudo from 'i18next-pseudo';
import ChainedBackend from 'i18next-chained-backend';
import Http from 'i18next-http-backend';
import en from './translations/en.json';
import { BUILD_ENV } from 'contants';

const isProd = BUILD_ENV === 'production';
const fallbackLanguage = 'en-GB';
const projectToken = '0548484b78d24259901786bf61a881fc'; // YOUR PROJECT TOKEN

const apiKey = {
  staging: 'a08f94cf8f094a78aff8889ffff0a6b0',
  production: '895da1169aff4765a46441df7a526dea',
  simulator: '895da1169aff4765a46441df7a526dea'
};

const projectAssetsToken = apiKey[BUILD_ENV] || apiKey.staging;

const cdnBaseUrl = 'https://cdn.simplelocalize.io';
const environment = isProd ? '_production' : '_latest';
const versionHash = isProd ? Math.random() : Math.random();

const getLocaleIfExists = (lng?: string | null) => {
  console.log('lng', lng);
  const simpleLng = lng?.split('-')[0];

  if (simpleLng?.includes('pt')) return 'pt-BR';
  if (simpleLng?.includes('en')) return 'en-GB';
  if (simpleLng?.includes('es')) return 'es-ES';
  if (simpleLng?.includes('pseudo')) return 'pseudo';

  return null;
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const lng =
  getLocaleIfExists(new URLSearchParams(window.location.search).get('lng')) ||
  getLocaleIfExists(Cookies.get(INSIDE_LNG)) ||
  getLocaleIfExists(navigator.language) ||
  fallbackLanguage;

i18n
  .use(
    new Pseudo({
      languageToPseudo: 'pseudo'
    })
  )
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    returnNull: false,
    postProcess: ['pseudo'],
    debug: false,
    load: 'currentOnly',
    fallbackLng: fallbackLanguage,
    lng,
    backend: {
      backends: [Http, Http],
      backendOptions: [
        {
          loadPath: `${cdnBaseUrl}/${projectToken}/${environment}/{{lng}}/{{ns}}?v=${versionHash}`
        },
        {
          loadPath: `${cdnBaseUrl}/${projectAssetsToken}/${environment}/{{lng}}/{{ns}}?v=${versionHash}`
        }
      ]
    },
    resources: {
      'en-GB': {
        translation: en
      }
    },
    preload: ['en'],
    partialBundledLanguages: true,
    defaultNS: 'translation',
    ns: ['translation', 'accommodation', 'tickets', 'assets', 'accommodation_room'],
    saveMissing: false,
    interpolation: { escapeValue: false }
  });

export default i18n;
